<template>
  <div class="pages">
    <div class="back-up">
      <a href="javascript:;" @click="$router.back()">返回上一级</a>
    </div>
    <div class="input-info">
      <div class="info-title">
        <div class="title">
          <span class="line"></span>
          <span>上传独家协议</span>
        </div>
      </div>
      <div class="upload">
        <div class="item" v-for="item in fileListComputed" :key="item">
          <img :src="item" alt="">
        </div>
        <div class="item" v-if="fileListComputed.length < 2">
          <img src="/static/images/scbj_bg.png" alt="">
          <input class="upload" ref="upload" @change="handleFileUploadChange" type="file" accept=".png,.jpg,.jpeg.gif">
        </div>
      </div>
    </div>

    <div class="button-wrapper">
      <div class="btn" :class="{loading:submit||this.protocol.length === 2}" @click="handleSubmitClick">
        <span>提交</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommissionUpload',
  data() {
    return {
      fileList: [],
      protocol: [],
      loading: false,
      submit: false,
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true
        let res = await this.http({
          url: '/api/my/reportDetail',
          method: 'GET',
          params: {
            report_id: this.$route.query.id
          }
        })
        this.protocol = res.data.protocol
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }

    },
    async handleFileUploadChange(e) {
      if (e.target.files.length === 0) {
        return
      }
      if (this.submit) {
        return
      }

      try {
        this.submit = true
        var formData = new FormData

        formData.append('file', e.target.files[0])
        let res = await this.http({
          url: '/api/common/uploadFile',
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data;charset=UTF-8'
          },
          data: formData
        })

        this.fileList.push(res.data.path)
      } catch (e) {
        console.log(e)
      } finally {
        this.$refs['upload'].value = ''
        this.submit = false
      }
    },
    async handleSubmitClick() {
      if (this.submit) {
        return
      }
      if (this.fileList.length === 0) {
        this.$message({
          type: 'warning',
          message: '请上传独家协议'
        })
        return
      }

      try {
        this.submit = true
        await this.http({
          url: '/api/my/reportProtocol',
          method: 'POST',
          data: {
            report_id: this.$route.query.id,
            protocol: this.fileListComputed.join('|'),
          }
        })
        this.$message({
          type: 'success',
          message: '操作成功'
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.submit = false
      }
    }
  },
  computed: {
    fileListComputed() {
      return [
        ...this.protocol,
        ...this.fileList
      ]
    }
  }
}
</script>

<style scoped lang="scss">
@import "CommissionUpload.scss";
</style>
